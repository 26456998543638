export enum Constants {
  ROLE_BASED_LEARNING_PATHS = 'Role-Based Learning Paths',
  LEARNING_PATHS = 'Learning Paths',
  ASSIGNED_TRAINING = 'Assigned Training',
  CERTIFICATIONS = 'Certifications',
  COURSES = 'Courses',
  SPECIALIZATION = 'Specializations',
  CAREER_MAP = 'Career Map',
  CAREER_RESOURCES = 'Career Resources',
}

export const CARD_SECURE_STORE_KEY = '3DS';

export enum SearchResultTypesConstants {
  COURSES = 'courses',
  PAGES = 'pages',
  RESOURCES = 'resources',
  TEMPLATES = 'templates',
}
